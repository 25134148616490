/*------------------------------------------------------------------
[Common]
*/
body {
  font-size: 16px;
  font-family: "Wix Madefor Text";
  line-height: 1.5;
  font-weight: 400;
  color: #fff;
  background-color: #10102a;
  padding-top: 112px;
}
@media only screen and (min-width: 768px) {
  body {
    padding-top: 140px;
  }
}
@media only screen and (min-width: 992px) {
  body {
    padding-top: 0;
  }
}
@media (max-width: 991px) {
  body.body-hidden {
    overflow: hidden;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
  height: unset;
  width: auto;
}

textarea {
  resize: none;
}

a {
  color: #fff;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #b9d6fe;
}

@media (min-width: 1500px) {
  .container-xl {
    max-width: 1424px;
  }
}

.wrapper {
  overflow: hidden;
}

.row.gx--16 {
  margin-left: -16px;
  margin-right: -16px;
}
.row.gx--16 > * {
  padding-left: 16px;
  padding-right: 16px;
}
@media only screen and (min-width: 1200px) {
  .row.gx-xl--20 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .row.gx-xl--20 > * {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*------------------------------------------------------------------
  [Buttons]
  */
button {
  cursor: pointer;
}
button:focus {
  outline: none;
}

.btn-clear {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.btn-custom {
  border-radius: 50px;
  font-size: 14px;
  line-height: 23px;
  padding: 12px 30px;
  white-space: nowrap;
  font-weight: 500;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .btn-custom {
    font-size: 16px;
    line-height: 1.4;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 600;
  }
}
.btn-custom .btn-text {
  position: relative;
  z-index: 1;
  display: block;
}

.dgm-btn-primary {
  background: linear-gradient(92deg, transparent 2.36%, rgba(36, 0, 255, 0.4) 81.69%, rgba(56, 96, 255, 0.64) 97.14%);
  box-shadow: 12px 12px 24px 0px rgba(38, 60, 255, 0.25);
  backdrop-filter: blur(3px);
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary {
    box-shadow: 0px 12px 20px 0px rgba(38, 60, 255, 0.25);
    backdrop-filter: blur(2px);
  }
}
.dgm-btn-primary::before,
.dgm-btn-primary::after {
  content: "";
  position: absolute;
  top: 0;
  border: 1px solid transparent;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  height: 100%;
  transition: all 150ms ease-in-out;
}
.dgm-btn-primary::before {
  width: 100%;
  left: 0;
  border-radius: 24px;
  background: linear-gradient(60deg, #a0b7ff, rgba(255, 255, 255, 0.16)) border-box;
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary::before {
    width: 40%;
    border-radius: 24px 0 0 24px;
    border-right-width: 0;
    background: linear-gradient(110deg, #a0b7ff, rgba(185, 202, 255, 0) 80%) border-box;
  }
}
.dgm-btn-primary::after {
  right: 0;
  opacity: 0;
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary::after {
    opacity: 1;
    width: 20%;
    right: 0;
    border-radius: 0 24px 24px 0;
    border-left-width: 0;
    background: linear-gradient(-55deg, #a0b7ff, rgba(185, 202, 255, 0) 60%) border-box;
  }
}
.dgm-btn-primary:hover {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary:hover::before {
    width: 50%;
    background: linear-gradient(90deg, #a0b7ff, #b9caff 100%) border-box;
  }
  .dgm-btn-primary:hover::after {
    width: 50%;
    background: linear-gradient(-90deg, #b9caff, #a0b7ff 100%) border-box;
  }
}
.dgm-btn-primary.dgm-btn-transparent {
  border: none;
  box-shadow: none;
  backdrop-filter: blur(3px);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 93%,
    rgba(160, 183, 255, 0.2) 100%
  );
  width: 100%;
  position: relative;
  overflow: hidden;
}
.dgm-btn-primary.dgm-btn-transparent::before {
  background: none;
  background-color: #10102a;
  content: "";
  position: absolute;
  top: 0;
  left: 1px;
  right: 1px;
  bottom: 1px;
}
.dgm-btn-primary.dgm-btn-transparent::after {
  display: none;
}

.dgm-btn-outline-dark {
  border-radius: 50px;
  border: 1px solid #f5f5f5;
  background: radial-gradient(141.42% 141.42% at 0% 0%, rgba(250, 250, 250, 0.1) 0%, rgba(246, 246, 246, 0) 100%);
  backdrop-filter: blur(6px);
  color: #fff;
}
.dgm-btn-outline-dark:hover {
  color: #fff;
}

.dgm-btn-dark {
  background: linear-gradient(92deg, rgba(38, 41, 71, 0.81) 2.36%, rgba(56, 96, 255, 0.14) 97.14%);
  backdrop-filter: blur(2px);
  color: #fff;
  border: 1px solid transparent;
}
.dgm-btn-dark:hover {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.3);
}

.dgm-btn-primary-dark {
  background: linear-gradient(92deg, rgba(56, 96, 255, 0.1) 1.31%, rgba(36, 0, 255, 0.2) 72.62%, transparent 98.69%);
  color: #fff;
}
.dgm-btn-primary-dark:hover {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary-dark:hover::before {
    width: 50%;
    background: linear-gradient(90deg, #a0b7ff, #a0b7ff 100%) border-box;
  }
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary-dark:hover::after {
    width: 50%;
    background: linear-gradient(-90deg, #a0b7ff, #b9caff 100%) border-box;
  }
}
.dgm-btn-primary-dark::before,
.dgm-btn-primary-dark::after {
  content: "";
  position: absolute;
  top: 0;
  border: 1px solid transparent;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  height: 100%;
  transition: all 150ms ease-in-out;
}
.dgm-btn-primary-dark::before {
  width: 100%;
  left: 0;
  border-radius: 24px;
  background: linear-gradient(60deg, #a0b7ff, rgba(255, 255, 255, 0.16)) border-box;
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary-dark::before {
    width: 36%;
    border-radius: 24px 0 0 24px;
    border-right-width: 0;
    background: linear-gradient(135deg, #a0b7ff, rgba(185, 202, 255, 0) 75%) border-box;
  }
}
.dgm-btn-primary-dark::after {
  right: 0;
  opacity: 0;
}
@media only screen and (min-width: 768px) {
  .dgm-btn-primary-dark::after {
    opacity: 1;
    width: 35%;
    right: 0;
    border-radius: 0 24px 24px 0;
    border-left-width: 0;
    background: linear-gradient(-30deg, #a0b7ff, rgba(185, 202, 255, 0) 60%) border-box;
  }
}

/*------------------------------------------------------------------
  [Typo]
  */
.title {
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1.25;
}
.title span {
  background: linear-gradient(180deg, #5fa5ff 3.66%, #4596ff 100%);
  background-clip: text;
  font-weight: 800;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.title em {
  font-style: normal;
}

.title-type-1 {
  font-size: 36px;
  letter-spacing: -0.36px;
}
@media only screen and (min-width: 768px) {
  .title-type-1 {
    font-size: 80px;
    letter-spacing: -2.4px;
  }
}

.title-type-2 {
  font-size: 24px;
  letter-spacing: -0.24px;
}
@media only screen and (min-width: 768px) {
  .title-type-2 {
    font-size: 60px;
    letter-spacing: -1.2px;
  }
}

.title-type-3 {
  font-size: 24px;
  letter-spacing: -0.24px;
}
@media only screen and (min-width: 768px) {
  .title-type-3 {
    font-size: 48px;
    letter-spacing: -0.96px;
  }
}

.title-type-4 {
  font-size: 24px;
  letter-spacing: -0.36px;
}
@media only screen and (min-width: 768px) {
  .title-type-4 {
    font-size: 36px;
  }
}

.title-type-5,
.article-title {
  font-size: 18px;
  letter-spacing: -0.36px;
}
@media only screen and (min-width: 768px) {
  .title-type-5,
  .article-title {
    font-size: 36px;
  }
}

.heading {
  margin-bottom: 12px;
}
@media only screen and (min-width: 768px) {
  .heading {
    margin-bottom: 40px;
  }
}

.fs-7 {
  font-size: 14px;
}

.fs-8 {
  font-size: 12px;
}

.text-light-blue {
  color: #b9d6fe;
}

.text-gradient {
  background: linear-gradient(180deg, #77a5ff 2.25%, #4584ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fw-extrabold {
  font-weight: 800;
}

/*------------------------------------------------------------------
  [Header]
  */

@keyframes startDisplayBlock {
  from {
    display: none; /* Start with display none */
    opacity: 0;
  }
  to {
    display: block; /* End with display block */
    opacity: 1;
  }
}
.header-site {
  position: relative;
  z-index: 10;
  background: linear-gradient(92deg, transparent 2.36%, rgba(36, 0, 255, 0.04) 81.69%, rgba(56, 96, 255, 0.06) 97.14%);
}
.fadeout {
  transition: all 2.5s ease;
  opacity: 0;
  transform: translateY(-100%);
}

.fadein {
  transition: all 2.5s ease;
  display: block;
  transform: translateY(-31%);
}
.scaleup {
  transition: all 2.5s ease;
  /* transform: scale(1.3) translateY(36px);  */
}
.moveup {
  transition: all 2.5s ease;
  transform: translateY(-10%);
  scale: 1.1;
}
.hide-left {
  transition: all 2.5s ease;
  transform: translateX(-1000px);
}
.hide-right {
  transition: all 2.5s ease;
  transform: translateX(1000px);
}
.hide-up {
  transition: all 2.5s ease;
  transform: translateY(-200%);
  opacity: 0;
}
.tab-content .group-after-animation.fadein {
  transform: translateY(0);
  opacity: 1;
}

.section-hero.before-animation .group-after-animation.fadein {
  transition: all 2.5s ease;
  opacity: 1;
  top: 0;
}
.section-hero.before-animation.nobg {
  background: transparent;
}

@media (max-width: 991px) {
  .header-site.show .bg-sidebar {
    display: block;
  }
  .header-site.show .navbar-expand-lg {
    border: none;
    background-color: transparent;
  }
}

.bg-sidebar {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg-sidebar img {
  min-width: 100%;
}

@media only screen and (min-width: 992px) {
  .navbar-expand-lg {
    padding: 0;
    margin: 0;
  }
}
@media (max-width: 991px) {
  .navbar-expand-lg {
    border-radius: 50px;
    border: 1px solid rgba(160, 183, 255, 0.8);
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(2px);
    padding: 11px 16px;
    position: fixed;
    z-index: 15;
    top: 44px;
    left: 12px;
    right: 12px;
  }
}
.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #fff;
  padding: 22px 24px;
}
@media only screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 22px 15px;
  }
}
@media only screen and (min-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 22px 24px;
  }
}
.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar-expand-lg .navbar-nav .nav-link:focus,
.navbar-expand-lg .navbar-nav .nav-link.active {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0) 100%);
}
.navbar-expand-lg .navbar-nav .nav-link:hover .txt,
.navbar-nav .nav-link:focus .txt,
.navbar-expand-lg .navbar-nav .nav-link.active .txt {
  background: var(--Blue-linear, linear-gradient(180deg, #77a5ff 2.25%, #4584ff 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbar-expand-lg .navbar-nav .nav-link .icon {
  margin-left: 6px;
}
@media only screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border-radius: 9px;
    border: 1px solid #2f80ed;
    background-color: #00052e;
    box-shadow: 0px 0px 3px 0px rgba(114, 161, 252, 0.49), 0px 0px 6px 0px rgba(114, 161, 252, 0.43),
      0px 0px 8px 0px rgba(114, 161, 252, 0.25), 0px 0px 9px 0px rgba(114, 161, 252, 0.07),
      0px 0px 10px 0px rgba(114, 161, 252, 0.01);
    padding: 9px 0;
    min-width: 258px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item {
    padding: 9px 27px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    color: #fff;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:hover,
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #fff;
    border-right: 1px solid var(--Blue-B50, #b9d6fe);
    border-left: 1px solid var(--Blue-B50, #b9d6fe);
    background: var(--Neutral-card-bg, #00052e);
    box-shadow: 0px 0px 14.9px 0px rgba(103, 106, 176, 0.66) inset;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:hover span,
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:focus span {
    background: var(--Blue-linear, linear-gradient(180deg, #77a5ff 2.25%, #4584ff 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width: 991px) {
  .navbar-brand {
    margin: 0 auto;
    padding: 0;
  }
}
.navbar-brand img {
  max-height: 24px;
  display: block;
}
@media only screen and (min-width: 992px) {
  .navbar-brand img {
    max-height: 38px;
  }
}
.navbar-dev {
  display: flex;
  align-items: center;
}

.navbar-divider {
  border: 1px solid var(--white);
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.language-link {
  display: inline-block;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  color: #828282;
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .language-link {
    font-size: 16px;
  }
}
.language-link:hover,
.language-link:focus,
.language-link.active {
  color: #fff;
}
.language-link:hover::after,
.language-link:focus::after,
.language-link.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.navbar-toggler {
  position: absolute;
  top: 0;
  right: 4px;
  border: none;
  padding: 10px;
  height: 100%;
  border-radius: 0 50px 50px 0;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler span {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
}
.navbar-toggler span::before,
.navbar-toggler span::after {
  content: "";
  display: block;
  width: 20px;
  left: 2px;
  height: 2px;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  transition: all ease-in-out 0.3s;
}
.navbar-toggler span::before {
  top: 8px;
}
.navbar-toggler span::after {
  bottom: 8px;
}
.navbar-toggler[aria-expanded="true"] span::before {
  transform: rotate(45deg);
  margin-top: 2px;
}
.navbar-toggler[aria-expanded="true"] span::after {
  transform: rotate(-45deg);
  margin-bottom: 4px;
}

.languages-mb a {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
  font-weight: 700;
}
.languages-mb a:hover,
.languages-mb a.active {
  background-color: #fff;
  color: #828282;
}

.dev-logo {
  margin-bottom: 10px;
  margin-left: 10px;
}

.side-menu {
  width: 250px;
  background-color: #f8f8f8;
  border-right: 1px solid #ddd;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
}

.side-menu h2 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #000000;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-menu ul li {
  padding: 10px 15px;
  margin-bottom: 5px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.side-menu ul li.active {
  background-color: #4489fa;
  color: white;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.content {
  margin-left: -250px;
  flex: 1;
  overflow: hidden;
}
